import { toInfoData } from "@/utils/transforms";

const convertArrayToObject = (array, key) => {
  const initialValue = {};
  return array.reduce((obj, item) => {
    return {
      ...obj,
      [item[key]]: item,
    };
  }, initialValue);
};

const totalMoney = (item, type) => {
  let code;
  // code = item.type === "S" ? "SS" : "AC";
  switch (type || item.type) {
    case "S":
      code = "SS";
      break;
    case "A":
      code = "AC";
      break;
    case "Q":
      return item.gross;
    case "I":
      return item.amount;
  }

  return parseFloat(
    item.entry_details
      .filter((el) => el.treasury.code == code)
      .reduce(function (acc, obj) {
        return acc + obj.gross;
      }, 0)
      .toFixed(2)
  );
};

export const drawValue = (item, field) => {
  // console.log("index: ", index);
  let ret = null;
  let value = null;
  let parts = field.key.split(".");
  let length = parts.length;
  // console.log("item: ", item);
  // console.log("field: ", field);
  if (length > 1) {
    // field in dot notation
    value = parts.reduce((acc, part) => acc && acc[part], item);
  } else {
    switch (field.key) {
      // virtual field
      case "broker":
        value = toInfoData(item.brokers[0], "brokerfullname", null, "\n");
        break;
      // case "money":
      //   value = item.money; // totalMoney(item);
      //   break;
      case "note":
        value = item.tasks.length;
        break;
      case "registry":
        value = toInfoData(item.registries[0], "registryfullname", null, "\n");
        break;
      case "agency_gross":
        value = parseFloat(
          (item.agency_prov_purchase + item.agency_prov_take).toFixed(2)
        );
        break;
      case "saler_gross":
        value = parseFloat(
          (item.saler_prov_purchase + item.saler_prov_take).toFixed(2)
        );
        break;
      case "insurance_policy_number":
        if (item.various_accountings && item.various_accountings.length) {
          value = item.various_accountings[0].pivot.insurance_policy_number;
        } else if (
          item.insurance_ancillaries &&
          item.insurance_ancillaries.length
        ) {
          value = item.insurance_ancillaries[0].insurance_policy.number;
        } else {
          value = "";
        }
        break;
      default:
        // basic field
        value = item[field.key];
        break;
    }
  }
  if (value !== null) {
    ret = field.formatter ? field.formatter(value) : value;
  }
  return field.key === "money" &&
    !["S", "T:Q", "T:A", "T:P"].includes(item.type)
    ? `-${ret}`
    : ret;
};

export const pushSortedIds = (id, ids, ordered) => {
  // return ordered.filter((e) => selectedIds.indexOf(e) > -1);
  ids.push(id);
  return ordered.filter((e) => ids.includes(e));
};

export const mergeIndex = (items1, items2, items4, items5, items6, items7) => {
  // based on actual fields definition, these are the items' fields required:
  // book_date
  // various_accountings
  // insurance_ancillaries
  // registries
  // gross
  // tasks
  // set the type? TODO: qualcosa per capire se è il sospeso oppure l'acconto...
  items1 = items1
    ? items1.map((obj) => {
        const {
          id,
          book_date,
          entry_details,
          various_accountings,
          insurance_ancillaries,
          registries,
          gross,
          tasks,
        } = obj;
        /* // LEX 19/01/2023: viene inviato (dalla submit) un payload senza gross
        // provo a saltarlo...
        if (gross == 0) {
          return null;
        } */
        return {
          id: `S${id}`,
          book_entry_id: id,
          registry_id: obj.registries.length ? obj.registries[0].id : null,
          name: obj.registries.length
            ? toInfoData(obj.registries[0], "registryfullname", null, "")
            : "",
          book_date,
          entry_details,
          various_accountings,
          insurance_ancillaries,
          registries,
          gross,
          tasks,
          type: "S",
          money: totalMoney(obj, "S"),
          enabled: true,
        };
      })
    : [];

  items2 = items2
    ? items2.map((obj) => {
        const {
          id,
          book_date,
          entry_details,
          various_accountings,
          insurance_ancillaries,
          registries,
          // gross,
          tasks,
        } = obj;
        return {
          id: `A${id}`,
          book_entry_id: id,
          registry_id: obj.registries.length ? obj.registries[0].id : null,
          name: obj.registries.length
            ? toInfoData(obj.registries[0], "registryfullname", null, "")
            : "",
          book_date,
          entry_details,
          various_accountings,
          insurance_ancillaries,
          registries,
          gross: 0,
          tasks,
          type: "A",
          money: totalMoney(obj, "A"),
          enabled: true,
        };
      })
    : [];
  // items3 enabled: false, type: "Q",
  /*
  items3 = items3
    ? items3.map((obj) => {
        const {
          id,
          // book_date,
          // entry_details,
          // various_accountings,
          payable,
          // registries,
          gross,
          // tasks,
        } = obj;
        return {
          id: `Q${id}`,
          book_entry_id: id,
          registry_id: payable.insurance_policy.registry.id,
          name: toInfoData(
            payable.insurance_policy.registry,
            "registryfullname",
            null,
            ""
          ),
          book_date: payable.effective_at,
          entry_details: [],
          various_accountings: [],
          insurance_ancillaries: [payable],
          registries: [payable.insurance_policy.registry],
          gross,
          tasks: [],
          type: "Q",
          money: gross,
          enabled: false,
        };
      })
    : [];
  */
  // items4 enabled: false, type: "I",
  items4 = items4
    ? items4.map((obj) => {
        const {
          id,
          unsolved_at,
          // entry_details,
          // various_accountings,
          // insurance_ancillaries,
          // registries,
          registry,
          amount,
          // tasks,
        } = obj;
        return {
          id: `I${id}`,
          book_entry_id: id,
          registry_id: registry.id,
          name: toInfoData(registry, "registryfullname", null, ""),
          book_date: unsolved_at,
          entry_details: [],
          various_accountings: [],
          insurance_ancillaries: [],
          registries: [registry],
          gross: 0,
          tasks: [],
          type: "I",
          money: amount, // totalMoney(obj, "I"),
          enabled: false,
        };
      })
    : [];
  items5 = items5
    ? items5.map((obj) => {
        return {
          id: `T${obj.id}`,
          book_entry_id: obj.id,
          registry_id: obj.insurance_policy.registry.id,
          name: toInfoData(
            obj.insurance_policy.registry,
            "registryfullname",
            null,
            ""
          ),
          book_date: obj.effective_at,
          entry_details: [],
          various_accountings: [],
          insurance_ancillaries: [obj],
          registries: [obj.insurance_policy.registry],
          gross: obj.gross,
          tasks: [],
          type: `T${obj?.code?.value ? ":" + obj.code.value : ""}`,
          money: obj.gross,
          enabled: false,
        };
      })
    : [];
  items6 = items6
    ? items6.map((obj) => {
        return {
          id: `T${obj.id}`,
          book_entry_id: obj.id,
          registry_id: obj.insurance_policy.registry.id,
          name: toInfoData(
            obj.insurance_policy.registry,
            "registryfullname",
            null,
            ""
          ),
          book_date: obj.effective_at,
          entry_details: [],
          various_accountings: [],
          insurance_ancillaries: [obj],
          registries: [obj.insurance_policy.registry],
          gross: obj.gross,
          tasks: [],
          type: `T${obj?.code?.value ? ":" + obj.code.value : ""}`,
          money: obj.gross,
          enabled: false,
        };
      })
    : [];
  items7 = items7
    ? items7.map((obj) => {
        return {
          id: `T${obj.id}`,
          book_entry_id: obj.id,
          registry_id: obj.insurance_policy.registry.id,
          name: toInfoData(
            obj.insurance_policy.registry,
            "registryfullname",
            null,
            ""
          ),
          book_date: obj.effective_at,
          entry_details: [],
          various_accountings: [],
          insurance_ancillaries: [obj],
          registries: [obj.insurance_policy.registry],
          gross: obj.gross,
          tasks: [],
          type: `T${obj?.code?.value ? ":" + obj.code.value : ""}`,
          money: obj.gross,
          enabled: false,
        };
      })
    : [];
  let items = items1
    .concat(items2)
    // .concat(items3)
    .concat(items4)
    .concat(items5)
    .concat(items6)
    .concat(items7);

  return items.sort((a, b) => {
    if (a.name.localeCompare(b.name) === 0) {
      return a.book_date < b.book_date ? -1 : 1;
    } else {
      return a.name.localeCompare(b.name);
    }
  });
};

export const getSemaphoreColor = (state) => {
  let ret = "";
  switch (state) {
    case 0:
      ret = "sem-yellow";
      break;
    case 1:
      ret = "sem-green";
      break;
    case -1:
      ret = "sem-red";
      break;
    default:
      ret = "white";
  }
  return ret;
};

export const getElaborateColor = (empty, used) => {
  let ret = "";
  switch (empty) {
    case true:
      ret = "ela-red";
      break;
    case false:
      ret = used ? "ela-yellow" : "ela-green";
      break;
    default:
      ret = "white";
  }
  return ret;
};

export const elaborate = (nIds, pIds, model) => {
  let initialValue = 0;
  let selPositives = {};
  let selNegatives = {};
  /* // LEX 19/01/2023: viene inviato (dalla submit) un payload senza gross..
  // provo a filtrare i sospesi a 0... */
  let negatives = Object.values(model).filter(
    (e) => nIds.includes(e.id) && e.type === "S" /*  && e.gross != 0 */
  );
  let nsum = negatives.reduce(
    (previousValue, currentValue) => previousValue + currentValue.money,
    initialValue
  );
  if (nsum <= 0) return;
  // inizializzo tutti i record (di acconto) con empty = false e used: 0
  // let pIds = Object.values(model)
  //   .filter((e) => e.type === "A")
  //   .map((e) => e.id);

  selPositives = convertArrayToObject(
    pIds.map((e) => ({
      id: e,
      book_entry_id: model[e].book_entry_id,
      empty: false,
      used: 0,
      money: model[e].money,
    })),
    "id"
  );
  // let nIds = Object.values(model)
  //   .filter((e) => e.type === "S")
  //   .map((e) => e.id);
  // devono esssere nello stesso ordine di selection!!!
  selNegatives = convertArrayToObject(
    nIds.map((e) => ({
      id: e,
      book_entry_id: model[e].book_entry_id,
      state: -1,
      covered: 0,
      money: model[e].money,
    })),
    "id"
  );
  let elaborations = [];
  // structure
  /* elaborations = [
    {
      def_detail: {
        "nvalue.id": {
          uses: [
            {
              money: "used",
            },
          ],
          def: {
            money: "- sum(uses.money)",
          },
          pivs: [
            // per ogni u in uses
            {
              left: "u.id",
              right: "def.id",
            },
          ],
        },
      },
      acc_details: [
        // per ogni u in uses
        {
          "pvalue.id": {
            acc: {
              money: "- used",
            },
            piv: {
              left: "u.id",
              right: "acc.id",
            },
          },
        },
      ],
    },
  ]; */
  // for (const [nkey, nvalue] of Object.entries(selNegatives)) {
  for (const nkey of nIds) {
    const nvalue = selNegatives[nkey];
    let defKey = nvalue.book_entry_id;
    // let coverable = model[nkey].money;
    let coverable = nvalue.money;
    let def_detail = {};
    let acc_details = [];
    let accKey;
    def_detail[defKey] = {
      uses: [],
      def: {},
      // pivs: [],
    };
    // for (const [pkey, pvalue] of Object.entries(selPositives)) {
    for (const pkey of pIds) {
      const pvalue = selPositives[pkey];
      if (pvalue.empty) {
        console.debug(`${pvalue.id} esaurito`);
        continue;
      }
      accKey = pvalue.book_entry_id;
      if (coverable > 0) {
        let available = pvalue.money;
        if (available == 0) {
          continue;
        }
        if (available >= coverable) {
          pvalue.empty = available === coverable ? true : false;
          pvalue.used = coverable;
          pvalue.money -= coverable;
          // addElaboration
          // def_detail
          def_detail[defKey].uses.push({
            money: pvalue.used,
          });
          let tot_money = parseFloat(
            def_detail[defKey].uses
              .reduce(function (acc, obj) {
                return acc + obj.money;
              }, 0)
              .toFixed(2)
          );
          // console.debug(
          //   "1) tot_money == coverable: ",
          //   tot_money == coverable ? "YES" : `NO: ${tot_money} != ${coverable}`
          // );
          def_detail[defKey].def = {
            money: -tot_money,
          };
          // let pivs = [];
          // for (const u of def_detail[nkey].uses) {
          //   pivs.push({
          //     left: u.id,
          //     right: def_detail[nkey].def.id,
          //   });
          // }
          // def_detail[nkey].pivs = pivs;
          // acc_detail
          // let accs = [];
          // for (const u of def_detail[nkey].uses) {
          let acc = {
            money: -pvalue.used,
            // id: null, // dopo la store ho l'id
          };
          // let piv = {
          //   left: u.id,
          //   right: acc.id,
          // };
          // accs.push({
          //   [pkey]: {
          //     acc,
          //     // piv,
          //   },
          // });
          // }
          // acc_details = accs;
          acc_details.push({
            [accKey]: {
              acc,
              // piv,
            },
          });

          break; // ho finito
        } else {
          pvalue.empty = true;
          pvalue.used = available;
          coverable -= available;
          // addElaboration
          def_detail[defKey].uses.push({
            money: pvalue.used,
          });
          let tot_money = parseFloat(
            def_detail[defKey].uses
              .reduce(function (acc, obj) {
                return acc + obj.money;
              }, 0)
              .toFixed(2)
          );
          // console.debug(
          //   "2) tot_money == coverable: ",
          //   tot_money == coverable ? "YES" : `NO: ${tot_money} != ${coverable}`
          // );
          def_detail[defKey].def = {
            money: -tot_money,
          };
          // let pivs = [];
          // for (const u of def_detail[nkey].uses) {
          //   pivs.push({
          //     left: u.id,
          //     right: def_detail[nkey].def.id,
          //   });
          // }
          // def_detail[nkey].pivs = pivs;
          // acc_detail
          // let accs = [];
          // for (const u of def_detail[nkey].uses) {
          let acc = {
            money: -pvalue.used,
          };
          // let piv = {
          //   left: null,
          //   right: null,
          // };
          acc_details.push({
            [accKey]: {
              acc,
              // piv,
            },
          });
          // // anche qui ho finito (non serve break !?)
          // break;
        }
      } else {
        break;
      }
    }
    // DEBUG
    if (
      !(
        Object.keys(def_detail[defKey].def).length &&
        def_detail[defKey].uses.length &&
        acc_details.length
      )
    ) {
      console.debug(">>>>>>>>>>> SENZA DATI <<<<<<<<<<<<<<<<<");
      console.debug(
        `def_detail[${defKey}].uses has ${def_detail[defKey].uses.length} items,
        def_detail[${defKey}].def has 
        ${Object.keys(def_detail).length} keys, acc_details has ${
          acc_details.length
        } items`
      );
    }

    // aggiungo se c'è effettivamente qualcosa...
    // LEX 19/01/2023: viene inviato (dalla submit) un payload senza gross
    // CHECK sembra che con questa condizione in &&, l'errore sui sospesi a 0 non compare...
    if (
      Object.keys(def_detail[defKey].def).length &&
      def_detail[defKey].uses.length &&
      acc_details.length
    ) {
      elaborations.push({
        def_detail,
        acc_details,
      });
    } else {
      console.debug("no elaboration for defKey: ", defKey);
    }
  }
  console.debug("elaborations: ", elaborations);
  // return selPositives;
  return elaborations;
};

export const semaphore = (nIds, pIds, model) => {
  let initialValue = 0;
  // inizializzo tutti i record (di sospeso) con state = -1 e covered: 0
  let selNegatives = convertArrayToObject(
    nIds.map((e) => ({ id: e, state: -1, covered: 0 })),
    "id"
  );
  let positives = Object.values(model).filter(
    (e) => pIds.includes(e.id) && e.type === "A"
  );

  let psum = positives.reduce(
    (previousValue, currentValue) => previousValue + currentValue.money,
    initialValue
  );

  let negative = 0;
  let remain = psum;
  for (const key of nIds) {
    if (remain <= 0) {
      // non ci sono acconti
      break;
    }
    let obj = Object.values(model).find((e) => e.id == key);
    negative = parseFloat(obj.money);
    remain -= negative;
    if (remain >= 0) {
      // coperto (verde)
      selNegatives[key].state = 1;
      // optionally:
      selNegatives[key].covered = negative;
    } else {
      if (Math.abs(remain) < negative) {
        // parziale (giallo)
        selNegatives[key].state = 0;
        // optionally:
        selNegatives[key].covered = (
          parseFloat(negative) - parseFloat(Math.abs(remain))
        ).toFixed(2);
      }
      // else {
      //   // non coperto (rosso) by default!
      //   value.state = -1;
      // }
    }
  }
  return selNegatives;
};
