<template>
  <div :class="items.length ? 'tableFixHead' : ''">
    <table>
      <thead>
        <th
          v-for="(field, index) in fields"
          :key="'tr-' + index"
          :class="field.thClass"
        >
          {{ field.label }}
        </th>
      </thead>
      <tbody v-for="(item, index) in items" :key="'tr-' + index">
        <tr
          v-if="!isLast(item, index)"
          :class="{
            [getSemColor(item.id)]: true,
          }"
        >
          <td
            v-for="(field, index) in fields"
            :key="'td-' + index"
            :class="{
              [field.tdClass]: field.tdClass,
              [item.type.toLowerCase()]: field.key === 'check1',
            }"
          >
            {{
              field.type !== "checkbox" ? drawValue(item, field, index) : null
            }}
            <div
              v-if="field.key === 'note_counter'"
              :role="
                item.tasks.filter((task) => task.task_type.value === 2).length
                  ? 'button'
                  : null
              "
              class="info-box"
              @click="
                item.tasks.filter((task) => task.task_type.value === 2).length
                  ? openNoteDetailModal(
                      item.tasks.filter((task) => task.task_type.value === 2)
                    )
                  : null
              "
              v-b-tooltip="
                toTooltipTask(
                  item.tasks.filter((task) => task.task_type.value === 2),
                  'Note'
                )
              "
            >
              {{
                item.tasks.filter((task) => task.task_type.value === 2).length
              }}
            </div>
            <base-checkbox
              v-if="field.type === 'checkbox' && item.enabled"
              name="check"
              class="check"
              v-model="inputVal[item.id].check"
              @change="onCheck($event, index, item)"
              :disabled="!item.enabled"
            />
          </td>
        </tr>
        <tr
          v-if="isLast(item, index)"
          :class="{
            [getSemColor(item.id)]: true,
          }"
        >
          <td
            v-for="(field, index) in fields"
            :key="'td-' + index"
            :class="{
              [field.tdClass]: field.tdClass,
              [item.type.toLowerCase()]: field.key === 'check1',
            }"
          >
            {{
              field.type !== "checkbox" ? drawValue(item, field, index) : null
            }}
            <div
              v-if="field.key === 'note_counter'"
              :role="
                item.tasks.filter((task) => task.task_type.value === 2).length
                  ? 'button'
                  : null
              "
              class="info-box"
              @click="
                item.tasks.filter((task) => task.task_type.value === 2).length
                  ? openNoteDetailModal(
                      item.tasks.filter((task) => task.task_type.value === 2)
                    )
                  : null
              "
              v-b-tooltip="
                toTooltipTask(
                  item.tasks.filter((task) => task.task_type.value === 2),
                  'Note'
                )
              "
            >
              {{
                item.tasks.filter((task) => task.task_type.value === 2).length
              }}
            </div>
            <base-checkbox
              v-if="field.type === 'checkbox' && item.enabled"
              name="check"
              class="check"
              v-model="inputVal[item.id].check"
              @change="onCheck($event, index, item)"
              :disabled="!item.enabled"
            />
          </td>
        </tr>
        <tr v-if="isLast(item, index)">
          <!-- <td colspan="4"></td>
          <td class="xxxsw text-left subtotal">SUBTOTALE</td>
          <td class="sw text-right subtotal">
            {{ toLocaleCurrency(subtotals[item.registry_id].gross) }}
          </td>
          <td class="sw text-right subtotal">
            {{ toLocaleCurrency(subtotals[item.registry_id].money) }}
          </td>
          <td class="xxxsw subtotal"></td> -->
          <td colspan="3"></td>
          <td class="text-left subtotal">SUBTOTALE</td>
          <td class="xxxsw subtotal"></td>
          <td class="sw text-right subtotal">
            {{ toLocaleCurrency(subtotals[item.registry_id].gross) }}
          </td>
          <td class="sw text-right subtotal">
            {{ toLocaleCurrency(subtotals[item.registry_id].money) }}
          </td>
          <td class="xxxsw subtotal"></td>
        </tr>
      </tbody>
      <tbody v-if="items.length">
        <tr>
          <td colspan="3"></td>
          <td class="text-left">TOTALE</td>
          <td class="xxxsw"></td>
          <td class="sw text-right">
            {{ toLocaleCurrency(totalgross()) }}
          </td>
          <td class="sw text-right">
            {{ toLocaleCurrency(totalmoney()) }}
          </td>
          <td class="xxxsw"></td>
        </tr>
      </tbody>
      <tbody v-if="!items.length">
        <tr>
          <td
            :colspan="fields.length"
            style="text-align: center !important; height: 40px"
          >
            Non ci sono informazioni da mostrare
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>
<script>
import { drawValue } from "@/utils/advance-payments";
import BaseCheckbox from "@/components/form/BaseCheckbox";
import { toLocaleCurrency } from "@/utils/strings";
import { toTooltipTask } from "@/utils/transforms";

export default {
  name: "sesana",
  data() {
    return {
      subtotals: {},
    };
  },
  props: {
    value: null,
    fields: {
      type: Array,
    },
    items: {
      type: Array,
    },
    semColor: null,
    elaColor: null,
  },
  components: {
    BaseCheckbox,
  },
  methods: {
    drawValue,
    toLocaleCurrency,
    toTooltipTask,
    onCheck(event, index, item) {
      this.$emit("check", event, index, item);
      // this.$emit("check", { event: event.check, index, item: event });
    },
    openNoteDetailModal(array) {
      this.$emit("open_note", array);
    },
    getSemColor(id) {
      return this.semColor(id);
    },
    getElaColor(id) {
      return this.elaColor(id);
    },
    subtotalmoney(itemId) {
      return this.items
        .filter((e) => e.registry_id === itemId)
        .reduce((acc, obj) => {
          return (
            acc +
            (["S", "T:Q", "T:A", "T:P"].includes(obj.type)
              ? obj.money
              : -obj.money)
          );
        }, 0);
    },
    subtotalgross(itemId) {
      return this.items
        .filter((e) => e.registry_id === itemId)
        .reduce((acc, obj) => {
          return acc + obj.gross;
        }, 0);
    },
    totalmoney() {
      return Object.values(this.subtotals).reduce((acc, obj) => {
        return acc + obj.money;
      }, 0);
    },
    totalgross() {
      return Object.values(this.subtotals).reduce((acc, obj) => {
        return acc + obj.gross;
      }, 0);
    },
    isLast(item, index) {
      let next = index + 1 < this.items.length ? this.items[index + 1] : null;
      let itemId = item.registry_id;
      if (!next) {
        this.subtotals[itemId] = {};
        this.subtotals[itemId].money = this.subtotalmoney(itemId);
        this.subtotals[itemId].gross = this.subtotalgross(itemId);
        return true;
      }
      if (itemId !== next.registry_id) {
        this.subtotals[itemId] = {};
        this.subtotals[itemId].money = this.subtotalmoney(itemId);
        this.subtotals[itemId].gross = this.subtotalgross(itemId);
        return true;
      }
      return false;
    },
  },
  computed: {
    inputVal: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },
};
</script>
<style lang="scss" scoped>
:deep(.check) {
  width: 1px;
}
:deep(.check .form-group) {
  margin-bottom: unset !important;
}
.tableFixHead {
  overflow-y: auto;
  max-height: 350px;
}
.tableFixHead thead th {
  position: sticky !important;
  top: 0;
}
table {
  width: 100%;
  & tbody {
    background-color: white;
  }
}
table tr th,
td {
  // background-color: #fff;
  color: #212529;
  padding: 2px 4px;
  text-align: left;
}
.zi2 {
  z-index: 2;
}
.xxxsw {
  width: 2%;
  min-width: 40px;
  max-width: 120px;
}
.xxsw {
  width: 4%;
  min-width: 60px;
  max-width: 140px;
}
.xsw {
  width: 6%;
  min-width: 80px;
  max-width: 160px;
}
.sw {
  width: 8%;
  min-width: 100px;
  max-width: 200px;
}
.mw {
  width: 10%;
  min-width: 120px;
  max-width: 240px;
}
.lw {
  width: 15%;
  min-width: 170px;
  max-width: 290px;
}
.verticalLine {
  border-right: 2px solid #f9991e;
}
.totals {
  background-color: #e1ddda;
  font-weight: bold;
}
// semaphore colors
// .sem-green {
//   background-color: rgb(156, 233, 156) !important;
// }
/* VERDE */
.sem-green td:nth-child(7) {
  background: rgb(255, 255, 255);
  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 1) 0%,
    rgb(255, 255, 255) 25%,
    rgba(0, 255, 0, 0.4) 100%
  );
}
// .sem-yellow {
//   background-color: rgb(236, 236, 128) !important;
// }
/* GIALLO */
.sem-yellow td:nth-child(7) {
  background: rgb(255, 255, 255);
  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 255, 255, 1) 25%,
    rgba(251, 255, 0, 0.4) 100%
  );
}
// .sem-red {
//   background-color: rgb(223, 158, 158) !important;
// }
/* ROSSO */
.sem-red td:nth-child(7) {
  background: rgb(255, 255, 255);
  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 255, 255, 1) 25%,
    rgba(255, 0, 0, 0.4) 100%
  );
}
// elaborate colors
// .ela-green {
//   background-color: rgb(156, 233, 156) !important;
// }
/* VERDE */
.ela-green td:nth-child(7) {
  background: rgb(255, 255, 255);
  background: linear-gradient(
    270deg,
    rgba(255, 255, 255, 1) 0%,
    rgb(255, 255, 255) 25%,
    rgba(0, 255, 0, 0.4) 100%
  );
}
// .ela-yellow {
//   background-color: rgb(236, 236, 128) !important;
// }
/* GIALLO */
.ela-yellow td:nth-child(7) {
  background: rgb(255, 255, 255);
  background: linear-gradient(
    270deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 255, 255, 1) 25%,
    rgba(251, 255, 0, 0.4) 100%
  );
}
// .ela-red {
//   background-color: rgb(223, 158, 158) !important;
// }
/* ROSSO */
.ela-red td:nth-child(7) {
  background: rgb(255, 255, 255);
  background: linear-gradient(
    270deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 255, 255, 1) 25%,
    rgba(255, 0, 0, 0.4) 100%
  );
}
// check colors (bases on type ("A", "S", "Q", "I"))
.a {
  height: 25px;
  width: 25px;
  // background-color: #a6f8a67a;
  background: radial-gradient(#56c456, white);
  // border-radius: 25%;
  // border: 1px solid #a6f8a6;
  display: inline-block;
}
.s {
  height: 25px;
  width: 25px;
  // background-color: #f8a09d86;
  background: radial-gradient(#af545186, white);
  // border-radius: 25%;
  // border: 1px solid #f8a09d;
  display: inline-block;
}
.q,
.i {
  height: 25px;
  width: 25px;
  // background-color: #8f8d8d88;
  background: radial-gradient(#77777777, white);
  // border-radius: 25%;
  // border: 2px solid limegreen;
  display: inline-block;
}

.subtotal {
  background-color: #e1ddda88;
  border-bottom: solid 1px #a59e9e;
}

// .lightgray {
//   background-color: lightgray;
// }
</style>
